import React from 'react';
import GlobalStyle from './styles/global';
import Home from './pages/Home/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Draw from './pages/Home/Draw/Draw';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({ config });


const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/draw" element={<Draw />} />

        </Routes>

        <GlobalStyle />

      </Router>
    </ChakraProvider>

  );
};

export default App;