import React from 'react';
import { Flex, Text, Image, VStack, Heading } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

// Update the TeamComposition type to match the API response structure
interface TeamComposition {
    top: [string, string, string | null];
    jungle: [string, string, string | null];
    mid: [string, string, string | null];
    adc: [string, string, string | null];
    support: [string, string, string | null];
}

interface TeamProps {
    team: TeamComposition;
    teamName: string;
    drawChampions: boolean;
}

const Team = ({ team, teamName, drawChampions }: TeamProps) => {
    return (
        <VStack spacing={4} align="stretch">
            <Heading size="md" textAlign="center">{teamName}</Heading>
            {Object.entries(team).map(([role, [participant, profileIcon, championName]], index) => (


                <Flex key={index} p={2} bg="var(--chakra-colors-gray-800)" borderRadius="md" alignItems="center" justifyContent="space-between" boxShadow="md">
                    <Flex alignItems="center" flexGrow={1}>
                        <Image
                            src={drawChampions && championName ? `https://ddragon.leagueoflegends.com/cdn/14.2.1/img/champion/${championName}.png` : profileIcon}
                            alt={championName || 'Default'}
                            boxSize="100px"
                            borderRadius="full"
                            marginRight="25px"
                        />
                        <VStack align="start">
                            <Text fontSize="lg" fontWeight="bold" color="#d4d4d6">
                                {role.toUpperCase()}
                            </Text>
                            <Text color="#ffffff">{participant}</Text>
                        </VStack>
                    </Flex>
                </Flex>
            ))}
        </VStack>
    );
};

const Draw = () => {
    const location = useLocation();
    const drawResult = location.state?.drawResult;

    if (!drawResult) {
        return <div>Loading...</div>;
    }

    const drawChampions = location.state?.drawChampions ?? false;

    return (
        <Flex direction="column" align="center" justify="start" w="100%" h="auto" pt="50px">
            <Flex direction={["column", "row"]} justify="center" align="center" wrap="wrap" gap={6} w="80%" maxW="1200px" m="auto">
                <Team team={drawResult.team1} teamName="Blue Team" drawChampions={drawChampions} />
                <Team team={drawResult.team2} teamName="Red Team" drawChampions={drawChampions} />
            </Flex>
        </Flex>
    );
};

export default Draw;
