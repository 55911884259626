import React, { useState, useEffect } from 'react';
import {
  Box, Flex, Input, VStack, Button, Heading, Text, RadioGroup, Radio, Stack, FormLabel, Switch, Container
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import loadingGif from '../../assets/Loading.gif';

const HomePage = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);


  const [participants, setParticipants] = useState(() => {
    const savedParticipants = localStorage.getItem('participants');
    return savedParticipants ? JSON.parse(savedParticipants) : Array(10).fill('');
  });
  const [map, setMap] = useState(() => localStorage.getItem('map') || 'summoners_rift');
  const [drawChampions, setDrawChampions] = useState(() => localStorage.getItem('drawChampions') === 'true');


  const colorMode = 'dark';

  useEffect(() => {
    localStorage.setItem('participants', JSON.stringify(participants));
    localStorage.setItem('map', map);
    localStorage.setItem('drawChampions', String(drawChampions));
  }, [participants, map, drawChampions]);

  const handleParticipantChange = (index: number, value: string) => {
    const newParticipants = [...participants];
    newParticipants[index] = value;
    setParticipants(newParticipants);
  };


  const handleDraw = async () => {

    setIsLoading(true);

    const formData = {
      region: "BR1",
      participants,
      map,
      drawChampions: drawChampions,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
      }
    };

    try {
      const response = await axios.post('https://api.leaguematchcreator.com/getComps', formData, config);
      navigate('/draw', { state: { drawResult: response.data, drawChampions: drawChampions } });
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Styles for dark mode
  const bgColor = { light: 'white', dark: 'gray.800' };
  const color = { light: 'black', dark: 'white' };
  const boxShadowColor = { light: 'md', dark: 'outline' };

  return (
    <Container maxW="container.xl" p={4} bg={bgColor[colorMode]} color={color[colorMode]}>
      {isLoading && (
        <Box position="fixed" top="0" left="0" width="100%" height="100%" bg="rgba(0, 0, 0, 0.5)" zIndex="1000">
          <Flex justify="center" align="center" height="100%">
            <img src={loadingGif} alt="Loading" />
          </Flex>
        </Box>
      )}
      <Box p={4} boxShadow={boxShadowColor[colorMode]}>
        <Box textAlign="center">
          <Heading mb={6}>Create Your Custom League of Legends Match</Heading>
        </Box>

        <Flex direction={{ base: 'column', md: 'row' }} gap="20px">
          <Box flex="1" boxShadow="md">
            <VStack spacing={2}>
              <Text fontSize="lg" fontWeight="bold">List of summoner names to be drawn</Text>
              {participants.map((name: string, index: number) => (
                <Input
                  key={index}
                  value={name}
                  onChange={(e) => handleParticipantChange(index, e.target.value)}
                  placeholder={`Summoner#BR1`}
                />
              ))}
            </VStack>
          </Box>

          <Box flex="1" boxShadow="md">
            <VStack spacing={6}>
              <Text fontSize="lg" fontWeight="bold">Which Map Will Be Used?</Text>
              <RadioGroup onChange={setMap} value={map}>
                <Stack direction="column">
                  <Radio value="summoners_rift">Summoner's Rift</Radio>
                  {/* <Radio value="howling_abyss">Howling Abyss</Radio> */}
                </Stack>
              </RadioGroup>

              <Text fontSize="lg" fontWeight="bold">Draw Champions for Each Lane?</Text>
              <FormLabel htmlFor="draw-champions-toggle">
                <Switch
                  id="draw-champions-toggle"
                  isChecked={drawChampions}
                  onChange={(e) => setDrawChampions(e.target.checked)}
                  colorScheme="purple"
                  size="lg"
                  mr={2}
                />
              </FormLabel>

              <Button colorScheme="purple" onClick={handleDraw}>
                Draw Teams
              </Button>
            </VStack>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default HomePage;
